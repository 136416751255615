import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchRefferal, setBannerRefferal, saveReferralInfo } from '../../store/actions';
import { selectRefferalInfo } from '../../store/selectors/entities';
import BannerRefferal from './banner-refferal';

// eslint-disable-next-line react/prop-types
const BannerRefferalContaier = ({
  webAlias = '',
  placementType = '',
  refferalToken = '',
  country = '',
}) => {
  const dispatch = useDispatch();

  const refferalInfo = useSelector(selectRefferalInfo);

  useEffect(() => {
    if (webAlias) {
      const constructedUrl =
        webAlias && placementType && refferalToken
          ? `${webAlias}?PlacementType=${placementType}&Token=${refferalToken}`
          : webAlias;

      if (placementType && placementType.length && refferalToken && refferalToken.length) {
        dispatch(saveReferralInfo({ placementType, placementToken: refferalToken, country }));
        dispatch(fetchRefferal(constructedUrl));
        return;
      }

      dispatch(saveReferralInfo({ startedWithLink: true, country }));
      dispatch(fetchRefferal(constructedUrl));
    }
  }, [webAlias, placementType, refferalToken]);

  const closeBanner = () => {
    dispatch(setBannerRefferal(false));
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BannerRefferal {...refferalInfo} closeBanner={closeBanner} />;
};

export default React.memo(BannerRefferalContaier, (props, nextProps) => props.webAlias === nextProps.webAlias);

BannerRefferalContaier.defaultProps = {
  webAlias: '',
  placementType: '',
  refferalToken: '',
  country: '',
};

BannerRefferalContaier.propTypes = {
  webAlias: PropTypes.string,
  placementType: PropTypes.string,
  refferalToken: PropTypes.string,
  country: PropTypes.string,
};
