import React from 'react';

import Instagram from '../../icons/svg/instagram.svg';
import TikTok from '../../icons/svg/tiktok.svg';
import WhatsApp from '../../icons/svg/whatsapp.svg';

export const socialLinks = [
  {
    id: 0,
    name: 'Instagram',
    url: '',
    src: <Instagram />,
  },
  {
    id: 1,
    name: 'TikTok',
    url: '',
    src: <TikTok />,
  },
  {
    id: 2,
    name: 'WhatsApp',
    url: '',
    src: <WhatsApp />,
  },
];
