import React from 'react';
import styles from './annual-sub-info.module.scss';

const string = {
  info:
    'Gold Learn Annual subscription only available via BTC payment. Do not have BTC funds? no worries, you can purchase BTC from',
  here: 'here',
  link: 'https://coinmarketcap.com/how-to-buy-bitcoin/',
};

const AnnualSubInfo = () => (
    <div className={styles.container}>
      {string.info}{' '}
      <a href={string.link} rel="noreferrer" target="_blank">
        {string.here}
      </a>
    </div>
  );

export default AnnualSubInfo;
