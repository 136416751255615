import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import Button from '../button';
import { socialLinks } from './constants';

import styles from './banner-refferal.module.scss';

const BannerRefferal = ({ showBanner, customerId, firstName, lastName, email, closeBanner }) =>
  showBanner && (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        <section>
          <h4 className={styles.bannerTitle}>
            You are referred by{' '}
            <span className={styles.userName}>{`${firstName} ${lastName}`}</span>
          </h4>

          <div className={styles.userInfo}>
            <div className={styles.userInfoSection}>
              <span className={styles.userInfoLabel}>ID:</span>
              <span className={styles.userInfoText}>{customerId}</span>
            </div>

            {email && (
              <div className={styles.userInfoSection}>
                <span className={styles.userInfoLabel}>Email:</span>
                <span className={styles.userInfoText}>
                  <a href={email} target="_blank" rel="noopener noreferrer">
                    {email}
                  </a>
                </span>
              </div>
            )}

            <div className={styles.userInfoSection}>
              <span className={styles.userInfoLabel}>Social:</span>

              {socialLinks.map((icon) => (
                <span key={icon.name} className={styles.userInfoIcon}>
                  <a href={icon.url} title={icon.name} target="_blank" rel="noopener noreferrer">
                    {icon.src}
                  </a>
                </span>
              ))}
            </div>
          </div>
        </section>

        <Button className={styles.bannerBtn} onClick={closeBanner}>
          {useIntl().formatMessage({ id: 'button.gotIt' })}
        </Button>
      </div>
    </div>
  );

BannerRefferal.defaultProps = {
  showBanner: false,
  customerId: '',
  firstName: '',
  lastName: '',
  email: '',
  closeBanner: () => {},
};

BannerRefferal.propTypes = {
  showBanner: PropTypes.bool,
  customerId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  closeBanner: PropTypes.func,
};

export default BannerRefferal;
